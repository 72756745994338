<template>
  <div>
    <b-card>
      <div class="row mb-3">
        <div class="col-12 d-flex">
          <span class="h2 mr-auto text-secondary">
            Nouvelle capacité technique
          </span>

          <div class="">
            <modal-actions @close="$router.go(-1)" />
          </div>
        </div>
      </div>

      <div class="shdow">
        <div
          class="d-flex flex-wrap justify-content-between align-items-center"
        >
          <span class="h3 text-secondary">Informations générales</span>
          <div>
            <b-button
              @click="createItem"
              type="submit"
              pill
              :disabled="submitingForm"
            >
              <b-spinner
                small
                v-if="submitingForm"
                class="text-white"
                label="Loading..."
              ></b-spinner>
              Confirmer</b-button
            >
          </div>
        </div>
        <hr class="mt-1 mb-3 bg-secondary" />

        <div class="row">
          <!-- First Col -->
          <div class="col-12 col-md-6">
            <b-form-group
              label-cols="4"
              label-cols-lg="3"
              label="Libellé : "
              label-class="font-weight-bold"
            >
              <b-form-input
                :class="{
                  'is-invalid':
                    $v.newCapacite.name.$error && $v.newCapacite.name.$dirty,
                }"
                type="text"
                placeholder="Libellé"
                v-model="newCapacite.name"
              ></b-form-input>
              <!-- ERROR MESSAGES -->
              <span
                style="font-size: x-small"
                class="text-danger font-weight-normal"
                v-if="$v.newCapacite.name.$dirty"
              >
                {{
                  !$v.newCapacite.name.required
                    ? "Champ obligatoire"
                    : !$v.newCapacite.name.minLength
                    ? `Le champ doit contenir au moins ${$v.newCapacite.name.$params.minLength.min} caractères.`
                    : ""
                }}
              </span>
              <!--  -->
            </b-form-group>
            <b-form-group
              label-cols="4"
              label-cols-lg="3"
              label="Niveau : "
              label-class="font-weight-bold"
            >
              <b-form-input
                :class="{
                  'is-invalid':
                    $v.newCapacite.niveau.$error &&
                    $v.newCapacite.niveau.$dirty,
                }"
                v-model="newCapacite.niveau"
                type="number"
                disabled
                placeholder="Niveau"
              ></b-form-input>
              <!-- ERROR MESSAGES -->
              <span
                style="font-size: x-small"
                class="text-danger font-weight-normal"
                v-if="$v.newCapacite.niveau.$dirty"
              >
                {{ !$v.newCapacite.niveau.required ? "Champ obligatoire" : "" }}
              </span>
            </b-form-group>

            <b-form-group
              label-cols="4"
              label-cols-lg="3"
              label="Capacité Rattachement : "
              label-class="font-weight-bold"
            >
              <v-select
                label="name"
                :reduce="(capacite) => capacite['@id']"
                v-model="newCapacite.parent"
                :options="capaciteRatechements"
                placeholder="Capacité Rattachement"
                @input="newCapacite.niveau = newCapacite.parent ? 2 : 1"
              ></v-select>
            </b-form-group>
          </div>

          <div class="col-12 col-md-6">
            <b-form-group
              label-cols="4"
              label-cols-lg="3"
              label="Description : "
              label-class="font-weight-bold"
            >
              <b-form-textarea
                type="text"
                placeholder="Description"
                v-model="newCapacite.description"
              ></b-form-textarea>
            </b-form-group>
            <!-- <b-form-group
              label-cols="4"
              label-cols-lg="3"
              label="Composants technique"
              label-class="font-weight-bold"
            >
              <multiselect
                multiple
                label="name"
                track-by="id"
                v-model="newCapacite.composants"
                :options="composantsList"
                placeholder="Choisir les composants technique"
              ></multiselect>
            </b-form-group> -->
          </div>
        </div>
      </div>
      <hr />
      <!-- {{ CAPACITE_TECHNIQUE.composants }} -->
      <div>
        <b-tabs class="tab-solid tab-solid-primary mt-3">
          <b-tab title="Composants techniques rattachés">
            <ComposantsTable :disabled="true" :composants="[]"
          /></b-tab>
          <b-tab title="Cadres rattachés"
            ><CadresTable :disabled="true" :cadres="[]" /></b-tab
        ></b-tabs>
      </div>
    </b-card>
  </div>
</template>

<script>
import ModalActions from "@/components/custom/ModalActions.vue";
import { required, minLength } from "vuelidate/lib/validators"; //validation
import Swal from "sweetalert2";
import { mapGetters } from "vuex";

import CadresTable from "./components/CadresTable";
import ComposantsTable from "./components/ComposantsTable";

export default {
  components: {
    ModalActions,
    CadresTable,
    ComposantsTable,
  },
  data: () => ({
    editMode: false,
    submitingForm: false,
    newCapacite: {
      name: "",
      description: "",
      parent: null,
      niveau: 1,
      composants: [],
    },
  }),
  validations: {
    newCapacite: {
      name: {
        required,
        minLength: minLength(5),
      },

      niveau: {
        required,
      },
    },
  },
  methods: {
    createItem() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.submitingForm = true;
        this.$store
          .dispatch("capaciteTechnique/createCapacite", {
            name: this.newCapacite.name,
            description: this.newCapacite.description,
            parent: this.newCapacite.parent,
            isPrincipal: false,
            niveau: parseInt(this.newCapacite.niveau),
          })
          .then(() => {
            this.submitingForm = false;
          });
      }
    },
  },
  created() {
    this.$store.dispatch("capaciteTechnique/fetchAllCapacites").then(() => {
      this.loadingData = false;
    });
    this.$store.dispatch("composant/fetchAllComposants");
  },
  computed: {
    ...mapGetters("capaciteTechnique", [
      "CAPACITES_TECHNIQUES",
      "CAPACITE_TECHNIQUE",
    ]),
    ...mapGetters("composant", ["COMPOSANTS"]),

    capaciteRatechements() {
      return this.CAPACITES_TECHNIQUES.filter(
        (capacite) => capacite.niveau === 1
      );
    },
    composantsList() {
      return this.COMPOSANTS;
    },
  },
  watch: {
    CAPACITE_TECHNIQUE() {
      this.submitingForm = false;

      Swal.fire({
        title: "La capacité technique est bien créée !",
        type: "success",
        confirmButtonText: "OK",
      }).then((result) => {
        if (result.value) {
          this.$router.push({
            name: "show-technical-capacity",
            params: {
              slug: this.CAPACITE_TECHNIQUE.slug,
              id: this.CAPACITE_TECHNIQUE.id,
              fromCreation: true,
            },
          });
        }
      });
    },
  },
};
</script>

<style></style>
